/* eslint-disable react-hooks/exhaustive-deps */
import { useToast } from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AgGridReact } from "ag-grid-react";
import React, { useEffect, useState } from "react";

import CellExpendViewModal from "../Enrichment/CellExpendViewModal";
import BottomPageActions from "./PageActions/BottomPageActions";
import DataTable from "./data-table";

import useSocket from "@/lib/socket";
import { downloadFile } from "@/lib/utils";
import enrichment from "@/services/enrichment.service";
import userService from "@/services/user.service";
import { useProcessingStore } from "@/stores/processing.store";
import { useTableStore } from "@/stores/table.store";
import { userStore } from "@/stores/user.store";
import type {
  ISocketData,
  ISocketMessageData,
  ISocketProcessingData,
  ISocketRefreshApiCalls,
} from "@/types/socket.types";
import { convertObject } from "@/utils";
import AutoRun from "./PageActions/AutoRun";
import RunningProcesses from "./PageActions/RunningProcesses";
import Pagination from "./Pagination";

interface Props {
  isRefetching: boolean;
  gridRef: React.RefObject<AgGridReact>;
}

const PersanaTable: React.FC<Props> = ({ gridRef, isRefetching }) => {
  const toast = useToast();
  const queryClient = useQueryClient();
  const updateUserStore = userStore((state) => state.updateState);
  const { mutateAsync: updateCredits } = useMutation({
    mutationFn: () => userService.getUserCreditDetails(),
    onSuccess: (response: any) => {
      updateUserStore({
        creditDetails: {
          creditLeft: response?.data?.creditLeft,
          creditUsed: response?.data?.creditUsed,
          planType: response?.data?.planType,
        },
      });
    },
  });

  const { subscribe } = useSocket();

  const currentUser = userStore((state) => state.currentUser);
  const updateRows = useTableStore((state) => state.updateRows);
  const updateRowsData = useTableStore((state) => state.updateRowsData);
  const expendedCellValue = useTableStore((state) => state.expendedCellValue);
  const isWebhook = useTableStore(
    (state) => !!state.tableData.metaData?.isWebhook,
  );
  const columns = useTableStore((state) => state.tableData.columns);
  const totalRows = useTableStore((state) => state.totalRows);
  const updateTableMetaDta = useTableStore((state) => state.updateTableMetaDta);
  const updateProcessingData = useProcessingStore(
    (state) => state.updateProcessingData,
  );
  const updateRowsCellValue = useTableStore(
    (state) => state.updateRowsCellValue,
  );

  const tableId = useTableStore((state) => state.tableData._id);
  const tableName = useTableStore((state) => state.tableData.name);
  const processesData = useProcessingStore((state) => state.processingData);
  const socketInstance = useTableStore((state) => state.socketInstance);

  const [lastReceivedDataAt, setLastReceivedDataAt] = useState(Date.now());

  useEffect(() => {
    if (!socketInstance?.connected) return;

    subscribe(tableId, async (data: ISocketData) => {
      const {
        processingData,
        rowsData = [],
        isForNew = false,
        isCreateRows,
        pereventRefresh = false,
        columnId,
      } = data;

      setLastReceivedDataAt(Date.now());

      console.log("processingData", processingData);

      console.log("SOCKET DATA 1", data);

      if (rowsData.length) {
        if (isForNew) {
          updateRows(rowsData);
        } else if (isWebhook) {
          updateRowsData(rowsData);
        }
      }

      if (rowsData.length && !isWebhook) {
        const rowsPayload = rowsData.map(({ cells, ...rowData }): any => {
          const cellsObj = convertObject(cells);

          const rowNode = gridRef.current?.api?.getRowNode(rowData._id);
          if (rowNode) {
            if (columnId) {
              updateRowsCellValue({
                rowId: rowData._id,
                columnId,
                cellValue: cells[columnId],
              });
              rowNode.setDataValue(columnId, cells[columnId]?.value);
            }
          }

          return {
            ...cellsObj,
            rowData,
          };
        });

        // Update table
        gridRef.current?.api.flushAsyncTransactions();
        if (isCreateRows) {
          gridRef.current?.api?.applyTransactionAsync({ add: rowsPayload });
        }
      }

      if (processingData) {
        updateProcessingData(processingData);
        if (!processingData.isProcessing) {
          await enrichment.callCronJobAPI();
          queryClient.refetchQueries({
            queryKey: ["table-running-processes", tableId],
          });
          await updateCredits();
          if (!pereventRefresh && !processingData?.isPreventRefresh) {
            await queryClient.refetchQueries({
              queryKey: ["table", tableId],
            });
          }
          gridRef.current?.api?.refreshCells();
        }
      }
    });

    subscribe(`${tableId}-processing`, async (data: ISocketProcessingData) => {
      console.log("SOCKET DATA 2", data);

      if (data.tableMetaData) {
        updateTableMetaDta({
          ...data.tableMetaData,
        });
      }
      if (data.downloadLink) {
        downloadFile({
          url: data.downloadLink,
          fileName: `${tableName}.csv`,
        });
      }
      if (data.isRefetch) {
        await queryClient.refetchQueries({
          queryKey: ["table", tableId],
        });
        queryClient.refetchQueries({
          queryKey: ["table-running-processes", tableId],
        });
      }

      if (data.isRefetchProcessing) {
        queryClient.refetchQueries({
          queryKey: ["table-running-processes", tableId],
        });
      }
    });

    subscribe(`${tableId}-message`, async (data: ISocketMessageData) => {
      console.log("SOCKET DATA 3", data);
      if (data?.title === "Remove duplicate rows") {
        queryClient.refetchQueries({
          queryKey: ["table-running-processes", tableId],
        });
      }
      toast({
        title: data.title,
        description: data.description,
        status: data.status,
        duration: data.duration,
        isClosable: true,
        position: "top-right",
      });
    });

    subscribe(
      `REFRESH_API_CALLS-${currentUser._id}`,
      async (data: ISocketRefreshApiCalls) => {
        console.log("SOCKET DATA 4 refresh api calls", data);
        if (data.key) {
          queryClient.refetchQueries({
            queryKey: [data.key],
          });
        }
      },
    );
  }, [tableId, isWebhook, currentUser, socketInstance]);

  useEffect(() => {
    let interval: NodeJS.Timeout | undefined = undefined;
    if (processesData?.length) {
      const TIME = 1 * 10 * 1000; // 10 seconds
      interval = setInterval(() => {
        if (Date.now() - lastReceivedDataAt > TIME) {
          queryClient.refetchQueries({
            queryKey: ["table", tableId],
          });
          queryClient.refetchQueries({
            queryKey: ["table-running-processes", tableId],
          });
        }
      }, TIME);
    } else {
      if (interval) {
        clearInterval(interval);
      }
    }

    return () => clearInterval(interval);
  }, [lastReceivedDataAt, processesData]);

  return (
    <div className="grow">
      <DataTable gridRef={gridRef} isRefetching={isRefetching} />
      <div className="flex items-center justify-between">
        <BottomPageActions gridRef={gridRef} tableId={tableId} />
        <RunningProcesses />

        <div className="mr-10 flex items-center gap-2">
          <div className="flex items-center gap-4">
            <AutoRun />
            <p className="text-sm text-darkTextGray">
              (Rows: {totalRows}, Columns: {columns.length})
            </p>
          </div>
          <Pagination gridRef={gridRef} />
        </div>
      </div>
      {expendedCellValue.isOpen && (
        <React.Suspense fallback={<div>Loading...</div>}>
          <CellExpendViewModal gridRef={gridRef} />
        </React.Suspense>
      )}
    </div>
  );
};

export default PersanaTable;
